import React from "react";
import styled from "styled-components";

const BranchContainer = styled.div`
  width: 100%;
`;

const BranchDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const BranchLinks = styled.div`
  height: 260px;
  display: grid;

  @media (max-width: 1279px) {
    height: auto;
  }
`;

const TeamLinks = styled.div`
  text-decoration: underline;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  a,
  a:visited {
    color: #1b1b1d;
    font-size: 24px;
    margin: 0;
    font-weight: 600;
  }

  a:last-child {
    width: 160px;
    justify-self: end;
    text-align: center;
  }
`;

const Map = styled.div`
  width: 160px;
  justify-self: end;
  img {
    height: 160px;
  }
`;
const Address = styled.div`
  margin-top: 20px;

  h4 {
    margin-bottom: 20px;
  }
`;
const InfoLinks = styled.div`
  align-self: end;

  h4 {
    margin-bottom: 25px;
  }

  a,
  a:visited {
    color: #1b1b1d;
    font-size: 18px;
    text-decoration: none;
    margin: 0;
  }

  @media (max-width: 1279px) {
    margin-top: 30px;
  }
`;

const Branch = ({
  city,
  address1,
  link,
  img,
  email,
  teamText,
  mapText,
  officeText,
  linkId,
}) => {
  const currentEmail = `mailto:${email}`;
  const currentLink = `#${linkId}`;

  console.log(img);
  return (
    <BranchContainer>
      <BranchDetails>
        <Address>
          <h4>{city}</h4>
          <p>{address1}</p>
        </Address>
        <Map>
          <a target="_blank" href={link}>
            <img
              src={
                !!img?.childImageSharp ? img?.childImageSharp.fluid.src : img
              }
            />
          </a>
        </Map>
      </BranchDetails>
      <BranchLinks>
        <TeamLinks>
          <a href={currentLink}>{teamText}</a>
          <a target="_blank" href={link}>
            {mapText}
          </a>
        </TeamLinks>
        <InfoLinks>
          <h4>{officeText}</h4>
          <a href={currentEmail}>{email}</a>
        </InfoLinks>
      </BranchLinks>
    </BranchContainer>
  );
};

export default Branch;
