import React from "react";
import styled from "styled-components";

const ListsContainer = styled.div`
  margin-bottom: 60px;

  h3 {
    margin-bottom: 30px;
  }
`;

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 40px;

  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
  }
`;

const Person = styled.div`
  img {
    height: auto;
    width: 100%;
    margin-bottom: 20px;
  }
`;
const PersonName = styled.div`
  margin-bottom: 20px;

  p,
  a {
    line-height: 20px;
    font-size: 14px;
  }
`;

const PersonContact = styled.div`
  p,
  a {
    line-height: 20px;
    font-size: 14px;
  }
`;

const TeamList = ({ city, people, linkId }) => {
  const ListOfPeople = people.map(({ person }) => {
    const image = person?.avatar;
    return (
      <Person>
        <img
          src={
            !!image?.childImageSharp ? image?.childImageSharp.fluid.src : image
          }
        />
        <div>
          <PersonName>
            <h4>{person.name}</h4>
            <p>{person.position}</p>
          </PersonName>
          <PersonContact>
            <p>{person.phone}</p>
            <p>{person.email}</p>
          </PersonContact>
        </div>
      </Person>
    );
  });

  return (
    <ListsContainer id={linkId}>
      <h3>{city}</h3>
      <List>{ListOfPeople}</List>
    </ListsContainer>
  );
};

export default TeamList;
