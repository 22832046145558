import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Wrapper } from "../components/GlobalStyles";
import styled from "styled-components";
import Branch from "../components/Branch";
import TeamList from "../components/TeamList";
import { Menu } from "../components/Menu/Menu";
import { Navbar } from "../components/Navbar";
import warszawa from "../img/warszawa.svg";
import rzeszow from "../img/rzeszow.svg";
import krakow from "../img/krakow.svg";

const Container = styled.div`
  margin-bottom: 90px;
  
  @media (max-width: 700px) {
    h2 {
      font-size: 50px;
    }
  }
`;

const Branches = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  margin-top: 70px;

  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
  }
`;

const TeamLists = styled.div`
  margin-top: 90px;
`;

const StyledContact = styled.div``;

const ContactPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        PL_ourBranches,
        ENG_ourBranches,
        city1,
        city1Address,
        city2,
        city2Address,
        city3,
        city3Address,
        googleAddressCity1,
        googleAddressCity2,
        googleAddressCity3,
        PL_team,
        ENG_team,
        PL_map,
        ENG_map,
        PL_recruitment,
        ENG_recruitment,
        recruitmentEmail,
        PL_office,
        ENG_office,
        officeEmail,
        PL_development,
        ENG_development,
        developmentEmail,
        PL_ourTeam,
        ENG_ourTeam,
        warsawTeam,
        rzeszowTeam,
        krakowTeam,
      },
    },
  } = data || {};

  const texts = {
    PL_ourBranches,
    ENG_ourBranches,
    PL_team,
    ENG_team,
    PL_map,
    ENG_map,
    PL_recruitment,
    ENG_recruitment,
    PL_office,
    ENG_office,
    officeEmail,
    PL_development,
    ENG_development,
    PL_ourTeam,
    ENG_ourTeam,
  };

  const [language, setLanguage] = useState("pl");

  const translate = (key) => {
    return `${language.toUpperCase()}_${key}`;
  };

  useEffect(() => {
    setLanguage(localStorage.getItem("language") || "pl");
  }, []);

  const handleLanguageClick = () => {
    if (language === "pl") {
      setLanguage("eng");
      localStorage.setItem("language", "eng");
    } else {
      setLanguage("pl");
      localStorage.setItem("language", "pl");
    }
  };
  return (
    <Layout>
      <StyledContact>
        <Menu
          language={language === "pl" ? "eng" : "pl"}
          handleLanguageClick={handleLanguageClick}
        />
        <Navbar />
        <Wrapper>
          <Container>
            <h2>{texts[translate("ourBranches")]}</h2>
            <Branches>
              <Branch
                city={city1}
                address1={city1Address}
                link={googleAddressCity1}
                img={krakow}
                email={recruitmentEmail}
                teamText={texts[translate("team")]}
                mapText={texts[translate("map")]}
                officeText={texts[translate("recruitment")]}
                linkId={"team-city1"}
              />
              <Branch
                city={city2}
                address1={city2Address}
                link={googleAddressCity2}
                img={rzeszow}
                email={officeEmail}
                teamText={texts[translate("team")]}
                mapText={texts[translate("map")]}
                officeText={texts[translate("office")]}
                linkId={"team-city2"}
              />
              <Branch
                city={city3}
                address1={city3Address}
                link={googleAddressCity3}
                img={warszawa}
                email={developmentEmail}
                teamText={texts[translate("team")]}
                mapText={texts[translate("map")]}
                officeText={texts[translate("development")]}
                linkId={"team-city3"}
              />
            </Branches>
          </Container>
          <Container>
            <h2>{texts[translate("ourTeam")]}</h2>
            <TeamLists>
              <TeamList
                city={city1}
                people={krakowTeam}
                linkId={"team-city1"}
              />
              <TeamList
                city={city2}
                people={rzeszowTeam}
                linkId={"team-city2"}
              />
              <TeamList
                city={city3}
                people={warsawTeam}
                linkId={"team-city3"}
              />
            </TeamLists>
          </Container>
        </Wrapper>
      </StyledContact>
    </Layout>
  );
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        PL_ourBranches
        ENG_ourBranches
        city1
        city1Address
        city2
        city2Address
        city3
        city3Address
        googleAddressCity1
        googleAddressCity2
        googleAddressCity3
        PL_team
        ENG_team
        PL_map
        ENG_map
        PL_recruitment
        ENG_recruitment
        recruitmentEmail
        PL_office
        ENG_office
        officeEmail
        PL_development
        ENG_development
        developmentEmail
        PL_ourTeam
        ENG_ourTeam
        warsawTeam {
          person {
            name
            phone
            position
            email
            avatar {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        rzeszowTeam {
          person {
            name
            phone
            position
            email
            avatar {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        krakowTeam {
          person {
            name
            phone
            position
            email
            avatar {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
